* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Raleway", sans-serif;
}
.banner {
  width: 100%;
  height: 100vh;
  background: url("");
  background-repeat: no-repeat;
  background-size: cover;
}
.navbarTop {
  background-color: #fff;
  -webkit-box-shadow: 2px 0 10px rgb(0 0 0 / 17%);
}
.navbar-nav a {
  font-size: 20px;
  color: rgb(8 8 8) !important;
  font-weight: 500;
}
.navbar-text {
  font-weight: 600;
  color: #ed1b24 !important;
  padding-top: 0.3rem;
  font-size: 1.8rem;
}
#divHeader {
  margin-top: 2rem;
  color: #e61818;
  font-weight: 800;
  font-size: 3.2rem;
}
.section {
  padding-top: 41px;
}

@media screen and (min-device-width: 0px) and (max-device-width: 299px) {
  .slideMain {
    height: 22vh !important;
  }
  #top {
    font-size: 10px !important;
    padding-bottom: 0px !important;
  }
  .slideMargin {
    margin-top: 101px !important;
  }
  .navTopMargin {
    margin-top: 24px !important;
  }
  .contactTopMargin {
    margin-top: 50px !important;
  }
  .card-text {
    font-size: 0.7rem;
  }
  .card-title {
    font-size: 0.8rem;
  }
  #youtubeVideo {
    width: 100% !important;
    height: 181px !important;
  }
  .resButton {
    width: "100%" !important;
  }
}
@media screen and (min-device-width: 300px) and (max-device-width: 480px) {
  .slideMain {
    height: 30vh !important;
  }
  #top {
    font-size: 12px !important;
    padding-bottom: 0px !important;
  }
  .slideMargin {
    margin-top: 101px !important;
  }
  .navTopMargin {
    margin-top: 24px !important;
  }
  .contactTopMargin {
    margin-top: 50px !important;
  }
  .card-text {
    font-size: 0.7rem;
  }
  .card-title {
    font-size: 0.8rem;
  }
  #youtubeVideo {
    width: 100% !important;
    height: 181px !important;
  }
  .chairmanImg {
    width:75%;
    border-radius:2px;
  }
  .resButton {
    width: "100%" !important;
  }
}
@media screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .slideMain {
    height: 50vh !important;
  }
  #top {
    font-size: 22px !important;
    padding-bottom: 0px !important;
  }
  .slideMargin {
    margin-top: 101px !important;
  }
  .navTopMargin {
    margin-top: 24px !important;
  }
  .contactTopMargin {
    margin-top: 50px !important;
  }
  .card-text {
    font-size: 0.7rem;
  }
  .card-title {
    font-size: 0.8rem;
  }
  #youtubeVideo {
    width: 100% !important;
    height: 281px !important;
  }
  .chairmanImg {
    width:65%;
    border-radius:2px;
  }
  .resButton {
    width: "80%" !important;
  }
}
@media screen and (min-device-width: 769px) and (max-device-width: 1300px) {
  .slideMain {
    height: 75vh !important;
  }
  #youtubeVideo {
    width: 100% !important;
    height: 381px !important;
  }
  .chairmanImg {
    width:28%;
    border-radius:2px;
  }
  .resButton {
    width: "60%" !important;
  }
}
@media screen and (min-device-width: 1301px) and (max-device-width: 2500px) {
  
  .chairmanImg {
    width:28%;
    border-radius:2px;
  }
}
/* .chairmanImg {
  width:55%;
  border-radius:2px;
} */
.card-header {
  background-color: rgb(26 160 135);
  color: #fff;
  font-size: 1.4rem;
}
.card-review {
  border: none;
}
.second-review {
  border-left: 2px dotted lightgrey;
  border-right: 2px dotted lightgrey;
}
.profile img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.container-review {
  background-color: white;
  text-align: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.58);
  border-radius: 16px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.header-review {
  padding-top: 35px;
  padding-bottom: 50px;
}
.quoteLeft {
  font-size: 9px;
  color: orangered;
  position: relative;
  bottom: 5px;
  padding-right: 2px;
}
.pdfIcon {

  position: relative;
  bottom: 5px;
  padding-right: 2px;
  font-size: 30px;
  color: rgb(254, 11, 7);
  margin-top: 7px !important;
}
.profileImg {
  -webkit-transform: translate3d(0, -50%, 0);
  -moz-transform: translate3d(0, -50%, 0);
  -o-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.shadow {
  box-shadow: 0 20px 40px rgb(0 0 0 / 58%) !important;
}
.number_0 {
  font-size: 60px;
  color: #827879;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
.number_append {
  font-size: 60px;
  color: #827879;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}
#top {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 23px;
  font-family: sans-serif !important;
}
.contactIcon {
  width: 96.5px;
  height: 85.03px;
}
.contactText {
  text-align: center;
  padding-top: 10%;
  letter-spacing: 0.2em;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 5%;
}
.contactText2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5em;
  max-width: 275px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  color: #00000099;
  font-family: nunito sans, sans-serif;
}
.noticeNo {
  background-color: #fbfbfb;
  display: block;
  padding: 3px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  box-shadow: 1px 1px 6px 0px;
  font-weight: 600;
  width: 35px;
  margin-top: 10px;
}
.noticeDay {
  font-size: 23px;
  font-weight: 700;
  color: #060;
  font-family: monospace;
}
.backImg {
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
}
.aboutUs {
  margin-top: 5%;
  color: rgb(249, 154, 73);
  font-family: none;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
}
.h4 {
  border-left: 3px solid #deaf28;
  text-align: center !important;
  margin-top: 30px;
}
.justify {
  text-align: justify;
  margin-top: 30px;
}
ul li a:hover,
ul li a:focus {
  color: rgb(15, 15, 15);
}
blink {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
.level {
  color: red;
  font-weight: 700;
}
.input-text {
  text-transform: uppercase;
}
.passportPhoto {
  border-width: 3px;
  border-style: double;
  border-color: rgb(195, 26, 26);
  width: 140px;
  height: 170px;
  float: right;
  margin-top: -167px;
  margin-right: 85px;
  text-align: center;
  padding: 70px 0;
  color: red;
  font-weight: 500;
}
.ihereby {
  width: 890px;
  margin-left: 56px;
  color: red;
  font-weight: 700;
  font-size: 15px;
  font-family: system-ui;
  font-style: italic;
  letter-spacing: 1px;
}
.sig {
  border: none;
  width: 228px;

  color: red;
  font-size: 16px;
  font-weight: 700;
}
.CourseListIcon {
  font-size: 14px;
  color: rgb(172, 22, 11);
  position: relative;

  margin-top: 5px;
}
.CourseListIcon2 {
  font-size: 44px;
  color: rgb(172, 22, 11);
  position: relative;
}
.CourseListText {
  font-size: 44px;
  color: rgb(172, 22, 11);
  font-weight: 700;
  margin-top: 10px;
}
.structureButton {
  border: 1px solid #000;
  padding: 8px;
  border-radius: 500px;
  background-color: #a00707;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-color: #a00707;
}
ul {
  cursor: default;
}
ol {
  cursor: default;
}
a.link {
  cursor: default;
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 100px;
  left: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
.footermenu:hover {
  color: #fbfbfb;
}
.ourAchiverDiv {
  padding: 40px;
}
.ourAchiverImg {
  box-shadow: 0px 0px 40px #999;
}
.nopadding {
  padding: 0 !important;
}
.jEkpCX{
	background-image: url(https://dirasa.in/img/logo1.png) !important;
}
.hDGnqR:after {
	content: 'Dirasa' !important;
	font-size: 14px;
} 
.hDGnqR{
	font-size: 0 !important;
}
.okbig {
  font-size: 80px;
  color: rgb(103, 177, 107);
  position: relative;
  bottom: 5px;
  padding-right: 2px;
}
.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: fixed ;
  margin:auto;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index: 1000;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.btna {
  display: block;
    width: 100%;
    height: 43px;
    background: #cd0f0f;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    line-height: 25px;
}
._2uc4w {
  padding: 12px !important;
  border-radius: 7px !important;
}
._1yCVn {
  padding: 6px !important;
}
